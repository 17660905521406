import $ from 'jquery';

class BrowserWarning {
  constructor() {
    const IE = this.ieVersion();
    const banner = $('.browser_warning_banner');
    if (!banner.length) return;

    if (IE.IsIE && IE.TrueVersion < 11) {
      banner.find('.not_supported').show();
      banner.find('.compatibility_mode').hide();
      banner.show();
    } else if (IE.IsIE && IE.CompatibilityMode) {
      banner.find('.not_supported').hide();
      banner.find('.compatibility_mode').show();
      banner.show();
    } else {
      banner.hide();
    }
  }

  ieVersion() {
    var n = {
        IsIE: !1,
        TrueVersion: 0,
        ActingVersion: 0,
        CompatibilityMode: !1,
      },
      i = navigator.userAgent.match(/Trident\/(\d+)/),
      t;
    return (
      i && ((n.IsIE = !0), (n.TrueVersion = parseInt(i[1], 10) + 4)),
      (t = navigator.userAgent.match(/MSIE (\d+)/)),
      t
        ? ((n.IsIE = !0), (n.ActingVersion = parseInt(t[1])))
        : (n.ActingVersion = n.TrueVersion),
      n.IsIE &&
        n.TrueVersion > 0 &&
        n.ActingVersion > 0 &&
        (n.CompatibilityMode = n.TrueVersion != n.ActingVersion),
      n
    );
  }
}

export function onReady() {
  new BrowserWarning();
}

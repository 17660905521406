export function setup() {
  if (typeof window.newrelic != 'object') return;
  try {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const availWidth = window.screen.availWidth;
    const availHeight = window.screen.availHeight;
    window.newrelic.setCustomAttribute(
      'screenResolution',
      screenWidth.toString() + 'x' + screenHeight.toString()
    );
    window.newrelic.setCustomAttribute('screenWidth', screenWidth);
    window.newrelic.setCustomAttribute('screenHeight', screenHeight);
    window.newrelic.setCustomAttribute(
      'screenAvailableResolution',
      availWidth.toString() + 'x' + availHeight.toString()
    );
    window.newrelic.setCustomAttribute('screenAvailableWidth', availWidth);
    window.newrelic.setCustomAttribute('screenAvailableHeight', availHeight);
  } catch (e) {
    // Ignore
  }
}

import $ from 'jquery';
import { blank } from 'src/utils';

function enableLoginButton(inputs, submitButton) {
  const hasEmptyValue = $.makeArray(inputs).some((input) =>
    blank($(input).val())
  );
  if (hasEmptyValue) {
    submitButton.prop('disabled', true);
  } else {
    submitButton.prop('disabled', false);
  }
}

function setup() {
  const form = $('form[data-check-form-submission]:not([data-remote])');
  if (!form) return;

  const inputs = form.find("input[type='text'], input[type='password']");
  const submitButton = form.find("input[type='submit']");
  inputs.on('input', () => enableLoginButton(inputs, submitButton));
  enableLoginButton(inputs, submitButton);
  form.on('submit', () => submitButton.prop('disabled', true));
}

export function onReady() {
  setup();
}

require.context('../images', true);

// IE compatibility
import 'core-js/stable';

import $ from 'jquery';
import {} from 'jquery-ujs';

import { onReady as browserWarningOnReady } from 'src/application/browser-warning';
import { onReady as signInAndPasswordSubmissionOnReady } from 'src/application/sign-in-and-password-form-submission';
import { setup as setupSessionsNew } from 'src/application/sessions-new';
import { setup as setupNewRelic } from 'src/application/new-relic';
import { onReady as testSupportOnReady } from 'src/application/test-support';

class Metrilio {
  constructor() {
    this.sessionsNew = setupSessionsNew;
  }
}

window.Metrilio = new Metrilio();

setupNewRelic();

// On document ready
$(() => {
  browserWarningOnReady();
  signInAndPasswordSubmissionOnReady();

  // Keep last, as it indicates all other onReady ran.
  testSupportOnReady();
});
